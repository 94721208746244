import React, { useCallback, useEffect } from 'react';
import { IconButton, CrossIcon, TypographyHeading, TypographyHero, Styler, Button, useInactivityDuration, useHasScrolledPercentage, useIntentToLeave, Stacker, Paper, Image, useDisclosure, Dialog, DialogContent, } from '@honeycomb/ui-core';
import { ImageOrigin, TriggerConditions, useGlobalContext } from '@honeycomb/data';
import { getBackgroundColor } from './utils/getBackgroundColor';
import { augmentImageUrl, ImageFormat } from '../../utils/images';
import { setCookie, checkCookie } from '../../utils/popUpCookie';
import { EmailSubscription } from './EmailSubscription/EmailSubscription';
const popupTheme = {
    width: 600,
};
const resolveTextColor = (textColor) => (textColor === 'Dark' ? 'text.heading' : 'text.inverse');
export function PopUp({ popUpItem }) {
    var _a, _b;
    const { configSettings: { HCPopUpTriggerInactivityDuration, HCPopUpTriggerScrolledPercentage, HCPopUpTriggerIntentToLeave, HCPopUpCookieExpirationDuration, }, } = useGlobalContext();
    const { heading, subHeading, background, textColor, triggerConditions, callToAction } = popUpItem;
    const tColor = resolveTextColor(textColor);
    const [dialogOpen, { open: handleOpenDialog, close: handleCloseDialog }] = useDisclosure(false);
    const handleClose = () => {
        handleCloseDialog();
        setCookie('popUpModalClosed', 'true', parseInt(HCPopUpCookieExpirationDuration) || 1);
    };
    const triggerConditionContains = useCallback((condition) => triggerConditions === null || triggerConditions === void 0 ? void 0 : triggerConditions.includes(condition), [triggerConditions]);
    const inactivityTriggered = useInactivityDuration(parseInt(HCPopUpTriggerInactivityDuration) || 15000);
    const hasScrolled = useHasScrolledPercentage(parseInt(HCPopUpTriggerScrolledPercentage) || 50);
    const isLeaving = useIntentToLeave(parseInt(HCPopUpTriggerIntentToLeave) || 20);
    useEffect(() => {
        if (!checkCookie('popUpModalClosed') &&
            inactivityTriggered &&
            triggerConditionContains(TriggerConditions.Inactivity)) {
            handleOpenDialog();
        }
    }, [handleOpenDialog, inactivityTriggered, triggerConditionContains]);
    useEffect(() => {
        if (!checkCookie('popUpModalClosed') &&
            hasScrolled &&
            triggerConditionContains(TriggerConditions.ScrollDepth)) {
            handleOpenDialog();
        }
    }, [handleOpenDialog, hasScrolled, triggerConditionContains]);
    useEffect(() => {
        if (!checkCookie('popUpModalClosed') && isLeaving && triggerConditionContains(TriggerConditions.ExitIntent)) {
            handleOpenDialog();
        }
    }, [handleOpenDialog, isLeaving, triggerConditionContains]);
    const hasBackgroundImage = background.__typename === 'BackgroundImage' && background.url;
    const imageUrl = hasBackgroundImage
        ? augmentImageUrl(ImageOrigin.Facade, background.url, 'custom', { breakpoint: 'xs', format: ImageFormat.Webp }, { width: popupTheme.width, height: popupTheme.width })
        : null;
    const bgColor = background.__typename === 'BackgroundColour' ? getBackgroundColor(background === null || background === void 0 ? void 0 : background.name) : 'transparent';
    const content = (React.createElement(Styler, { pt: { xs: 16, m: 20 }, pb: { xs: 8, m: 10 }, px: { xs: 4, m: 6 }, pos: "relative", zIndex: 1, "data-id": "generic-popup-modal-content" },
        React.createElement(Stacker, { spacing: { xs: 3, l: 6 }, alignItems: "center" },
            React.createElement(TypographyHero, { component: "h2", "data-id": "generic-popup-heading", color: tColor, ta: "center", size: "2xl" }, heading),
            subHeading && (React.createElement(TypographyHeading, { component: "h3", "data-id": "generic-popup-subheading", color: tColor, ta: "center", size: "l" }, subHeading))),
        callToAction && callToAction.__typename === 'Button' && (React.createElement(Styler, { display: "flex", justifyContent: "center", mt: { xs: 6, m: 8 } },
            React.createElement(Button, { "data-id": "generic-popup-button", color: "callToAction", size: "xl", variant: callToAction.variant, href: (_a = callToAction.href) !== null && _a !== void 0 ? _a : '#' }, (_b = callToAction.label) !== null && _b !== void 0 ? _b : ''))),
        callToAction && callToAction.__typename === 'EmailSignUp' && (React.createElement(Paper, { w: "100%", mt: { xs: 4, m: 9 }, p: 4, "data-id": "generic-popup-email-signup-box" },
            React.createElement(EmailSubscription, { emailSignUp: callToAction })))));
    return (React.createElement(Dialog, { open: dialogOpen, onClose: handleClose, size: `${popupTheme.width}px`, SurfaceProps: { bg: bgColor, h: hasBackgroundImage ? `${popupTheme.width}px` : undefined } },
        React.createElement(DialogContent, { disableGutters: true },
            hasBackgroundImage && (React.createElement(Image, { src: imageUrl, pos: "absolute", top: 0, left: 0, w: "100%", h: "100%", objectFit: "cover" })),
            React.createElement(IconButton, { "data-id": "generic-popup-close-icon", size: "s", color: textColor === 'Dark' ? 'primary' : 'white', onClick: handleClose, label: "Close", pos: "absolute", top: 3, right: 3, zIndex: 2 },
                React.createElement(CrossIcon, null)),
            content)));
}
