var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback } from 'react';
import { useEmailSubscribe, useGlobalContext } from '@honeycomb/data';
import { Button, FlexGrid, Notification, TextField, TickIcon, TypographyBody } from '@honeycomb/ui-core';
import isEmail from 'validator/lib/isEmail';
import { reducer, SubscribeEmailEnum } from './EmailSubscriptionReducer';
import { setCookie } from '../../../utils/popUpCookie';
const initialState = {
    email: '',
    reasonCode: '',
    loading: false,
    error: '',
    success: false,
};
export function EmailSubscription({ emailSignUp }) {
    const { resourceStrings: { HC_VALIDATE_EMAIL }, configSettings: { HCPopUpSubscribeCookieExpirationDuration }, } = useGlobalContext();
    const { emailButtonText, fieldText, reasonCode, confirmationHeader, confirmationSubHeader } = emailSignUp;
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const [emailSubscriber] = useEmailSubscribe([(params) => params]);
    const validateEmail = useCallback((val) => {
        if (!val || !isEmail(val)) {
            dispatch({ type: SubscribeEmailEnum.SETERRORMESSAGE, error: HC_VALIDATE_EMAIL });
            return false;
        }
        dispatch({ type: SubscribeEmailEnum.SETERRORMESSAGE, error: '' });
        return true;
    }, [HC_VALIDATE_EMAIL]);
    const onEmailChangeClick = useCallback((event) => {
        dispatch({
            type: SubscribeEmailEnum.SETEMAIL,
            email: event.currentTarget.value,
            source: reasonCode,
        });
        validateEmail(event.currentTarget.value);
    }, [validateEmail, reasonCode]);
    const subscribeEmail = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        if (!emailSubscriber.error && emailSubscriber.loading === false && !state.success) {
            yield emailSubscriber.emailSubscribe({
                request: {
                    email: state.email || '',
                    source: state.reasonCode || '',
                },
            });
            dispatch({ type: SubscribeEmailEnum.SETSUBSCRIBE });
            setCookie('popUpModalClosed', 'true', parseInt(HCPopUpSubscribeCookieExpirationDuration) || 1);
        }
    }), [state.email, state.reasonCode, state.success, emailSubscriber, HCPopUpSubscribeCookieExpirationDuration]);
    const submit = useCallback((event) => {
        event.preventDefault();
        if (!validateEmail(state.email || '')) {
            dispatch({ type: SubscribeEmailEnum.SETERRORMESSAGE, error: HC_VALIDATE_EMAIL });
        }
        else {
            dispatch({ type: SubscribeEmailEnum.ISSUBSCRIBE });
            subscribeEmail();
        }
    }, [validateEmail, state.email, subscribeEmail, HC_VALIDATE_EMAIL]);
    const placeHolderText = state.error && state.email === '' ? HC_VALIDATE_EMAIL : '';
    const labelText = state.error && state.email !== '' ? HC_VALIDATE_EMAIL : fieldText;
    return (React.createElement("div", { "data-id": "generic-email-popup" }, state.success ? (React.createElement(Notification, { "data-id": "generic-email-popUp-confirmation", color: "success", cornerStyle: "rounded", icon: React.createElement(TickIcon, null) },
        React.createElement(TypographyBody, { weight: "bold" }, confirmationHeader),
        confirmationSubHeader)) : (React.createElement("form", { noValidate: true, autoComplete: "off", onSubmit: submit },
        React.createElement(FlexGrid, { container: true, justifyContent: "center", spacing: 3, "data-id": "generic-email-popup-grid" },
            React.createElement(FlexGrid, { item: true, xs: 12, m: 8 },
                React.createElement(TextField, { name: "email", id: "email", type: "email", "data-id": "generic-email-popup-textbox", fullWidth: true, label: labelText, placeholder: placeHolderText !== null && placeHolderText !== void 0 ? placeHolderText : '', required: true, value: state.email, validationStatus: state.error ? 'error' : undefined, onChange: onEmailChangeClick, variant: "alternative" })),
            React.createElement(FlexGrid, { item: true, xs: 12, m: "fill" },
                React.createElement(Button, { color: "callToAction", size: "xl", loading: state.loading, fullWidth: true, type: "submit", alignSelf: "center", "data-id": "generic-email-popup-button" }, emailButtonText !== null && emailButtonText !== void 0 ? emailButtonText : '')))))));
}
