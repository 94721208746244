import React, { useRef } from 'react';
import { OptionMenuProvider, OptionMenu, OptionList, OptionListItem, Option, ChevronRightIcon, Dialog, } from '@honeycomb/ui-core';
const renderItem = (item, index, modalContentRef) => {
    const elements = [];
    const items = item.items || [];
    const hasChildren = items.length > 0;
    const url = (!hasChildren && item.url) || null;
    const handleScroll = () => {
        const contentRef = modalContentRef.current;
        if (contentRef) {
            contentRef.scrollTop = 0;
        }
    };
    elements.push(React.createElement(OptionListItem, { key: `option-${index.toString()}` },
        React.createElement(Option, { button: true, href: url || undefined, actionIcon: hasChildren ? React.createElement(ChevronRightIcon, { biDirectional: true, size: "2xs" }) : undefined, cornerStyle: "square", defaultGutters: true, onClick: !url ? handleScroll : undefined }, item.text),
        hasChildren && (React.createElement(OptionMenu, { title: item.text || '' },
            React.createElement(OptionList, null, items.map((nestedItem, nestedItemIndex) => {
                return nestedItem && renderItem(nestedItem, nestedItemIndex, modalContentRef);
            }))))));
    return elements;
};
export function NavigationMenu(props) {
    var _a;
    const { menuData, title, open, onClose } = props;
    const modalContentRef = useRef(null);
    return (React.createElement(Dialog, { position: "drawer-right", open: open, onClose: onClose, size: "s" },
        React.createElement(OptionMenuProvider, { ref: modalContentRef },
            React.createElement(OptionMenu, { title: title, open: open }, menuData ? (React.createElement(OptionList, null, (_a = menuData.items) === null || _a === void 0 ? void 0 : _a.map((item, index) => {
                return renderItem(item, index, modalContentRef);
            }))) : (React.createElement("div", null, "NO MENU DATA"))))));
}
