import React from 'react';
import { UnsafeHTML, TypographyBody, Styler, TypographyStyler, Dialog, DialogContent, DialogHeader, } from '@honeycomb/ui-core';
import { useModal, useNotification, NotificationTypes } from '@honeycomb/data';
export function Modal() {
    var _a, _b, _c, _d;
    const [currentModal, _setCurrentModal, setClosed, setEmpty] = useModal();
    const [_currentNotification, setCurrentNotification] = useNotification();
    const { data } = currentModal;
    const heading = (_a = data === null || data === void 0 ? void 0 : data.currentModal) === null || _a === void 0 ? void 0 : _a.heading;
    const subheading = (_b = data === null || data === void 0 ? void 0 : data.currentModal) === null || _b === void 0 ? void 0 : _b.subheading;
    const body = (_c = data === null || data === void 0 ? void 0 : data.currentModal) === null || _c === void 0 ? void 0 : _c.body;
    const isOpen = ((_d = data === null || data === void 0 ? void 0 : data.currentModal) === null || _d === void 0 ? void 0 : _d.isOpen) || false;
    const handleClose = () => {
        setClosed();
        setCurrentNotification({ isOpen: false, notificationType: NotificationTypes.None });
    };
    const handleExited = () => {
        setEmpty();
    };
    return (React.createElement(Dialog, { onClose: handleClose, onExited: handleExited, open: isOpen },
        React.createElement(DialogHeader, { withCloseButton: true, border: false }, heading),
        React.createElement(DialogContent, null,
            React.createElement(Styler, { pt: 1, pb: 5 },
                subheading && (React.createElement(TypographyBody, { mb: 4 },
                    React.createElement(UnsafeHTML, null, subheading))),
                React.createElement(TypographyStyler, null,
                    React.createElement(UnsafeHTML, null, body))))));
}
